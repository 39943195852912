.btn-primary {
  color: #fff;
  background-color: #00467f;
  border-color: #00467f;
}
.modal-75w {
  max-width: none !important;
  width: 75%
}
.frontend {
  font-size: 1.25rem
}
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.appointment-form-container {
    background-color: #D4DDD0;
}
.appointment-form-container .card-body {
    background-color: #E5EEDF;
    border: solid 1px #000;
}

.react-date-picker__inputGroup__year {
    width: 2.5rem !important;
}
.react-date-picker__inputGroup__divider {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.display-linebreak {
  white-space: pre-line;
}
.footer {
    position: absolute;
    text-align: center;
    bottom: 0;
    font-size: 1rem;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

.frontend .footer {
    height: 2.5rem;
    line-height: 2.5rem;
    padding-bottom: 2rem;
}

.frontend .step-container-questions .asterisk, .login-form-container .asterisk {
  display: none
}
.frontend .step-container-questions .form-label {
  font-weight: 700
}
.frontend .form-check label {
    margin-left: .75rem !important;
}

.frontend .form-check-input {
    border: solid 0.125em #8a8a8a;
    width: 1.25em;
    height: 1.25em;
}

.frontend .form-label[for=bookingFormId] {
    font-weight: bold;
}

.frontend .form-label[for=bookingFormId] .asterisk {
    display: none;
}

.frontend .react-date-picker__inputGroup__input {
  min-width: 3.54em;
}

@media print {
  .frontend {
    font-size: 1rem
  }
  .frontend h1 {
    font-size: 1.75rem
  }
  .frontend h2 {
    font-size: 1.5rem
  }
  .frontend h3 {
    font-size: 1.25rem
  }
}
